import SubHeader from '@/components/sub-header';
<template>
  <div class="main">
    <div class="info">
      <p>根据国家工信部《信息登记规定》（部令第25号）现所有物联网卡都需要实名认证，给您带来不便，还请谅解。</p>
    </div>
    <div class="input-wrap">
      <div class="item">
        <h4 class="title">流量卡号</h4>
        <div class="input-con">
           <input type="text" class="input" v-model.trim= "cartNum" placeholder="请输入流量卡号">
        </div>
      </div>
      <div class="item">
        <h4 class="title">真实姓名</h4>
        <div class="input-con">
           <input type="text" class="input" v-model.trim= "name" placeholder="请输入姓名">
        </div>
      </div>
      <div class="item">
        <h4 class="title">身份证号</h4>
        <div class="input-con">
           <input type="text" class="input" v-model.trim= "userID" placeholder="请输入身份证号">
        </div>
      </div>
      <div class="item">
        <h4 class="title">手机号码</h4>
        <div class="code-con">
          <div class="input-con">
           <input type="text" class="input" v-model.trim= "cellphone" placeholder="请输入手机号码" @input="checkCellphone">
          </div>
          <div :class="{'btn-code': true, 'success': isSendMsg }" @click="sendCode()">{{ msgText }}</div>
        </div>
      </div>
       <div class="item">
        <h4 class="title">验证码</h4>
        <div class="input-con">
           <input type="text" class="input" v-model.trim= "code" placeholder="请输入验证码">
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <button type="button" class="btn" @click="submit()">查询</button>
    </div>
    <div @click="$router.go(-1)">返回</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
export default {
  name: 'certification',
  components: {
  },
  data () {
    return {
      cartNum: '',
      name: '',
      userID: '',
      cellphone: '',
      code: '',
      isSendMsg: false,
      msgText: '获取验证码'
    }
  },
  created () {
    this.timer = null
  },
  mounted () {
    document.title = this.$route.meta.title
  },
  methods: {
    // 提交按钮
    submit () {
      if (this.cartNum === '') {
        Toast('请输入流量卡号')
        return
      }
      if (this.name === '') {
        Toast('请输入姓名')
        return
      }
      if (this.userID === '') {
        Toast('请输入身份证号')
        return
      }
      if (!this.userID.match(/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/)) {
        Toast('请输入正确的身份证号码')
        return
      }
      if (this.cellphone === '') {
        Toast('请输入手机号码')
        return
      }
      if (!this.cellphone.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)) {
        Toast('请输入正确的手机号')
        return
      }
      if (this.code === '') {
        Toast('请输入短信验证码')
        return
      }
      console.log('调用接口')
    },
    // 发送验证码
    sendCode () {
      if (this.cellphone === '') {
        Toast('请输入手机号码')
        return
      }
      if (!this.cellphone.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)) {
        Toast('请输入正确的手机号')
        return
      }
      let time = 60
      this.msgText = `(${time}秒后可重新获取)`
      this.isSendMsg = false
      this.timer = setInterval(() => {
        if (time > 0) {
          time--
          this.msgText = `(${time})秒后可重新获取`
        } else {
          clearInterval(this.timer)
          this.msgText = '获取验证码'
          this.isSendMsg = true
        }
      }, 1000)
    },
    // 检查手机好是否正确
    checkCellphone () {
      let isChecked = true
      if (this.cellphone === '') {
        isChecked = false
      }
      if (!this.cellphone.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)) {
        isChecked = false
      }
      if (isChecked) {
        this.isSendMsg = true
      } else {
        this.isSendMsg = false
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="scss" scoped>
  .main {
    padding: 1rem 0 0;
  }
  .info {
    padding:0 1.25rem 1.875rem;
    font-size: .75rem;
    line-height: 1.125rem;
    color: #b2b2b2;
  }
  .input-wrap{
    border-bottom: 1px solid #f2f2f2;
    background-color: #fff;
    .item{
      display: flex;
      height: 2.75rem;
      padding: 0 1.25rem;
      align-items: center;
      border-top: 1px solid #e5e5e5;
      .title {
        width: 4.5rem;
        font-size: .875rem;
        margin-right: 1.25rem;
      }
      .input-con{
        flex: 1;
        overflow: hidden;
        height: 2.75rem;
      }
      .input{
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        color:#666;
      }
      .code-con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      .btn-code {
        margin-left: 0.625rem;
        min-width:5.25rem;
        padding: 0 0.4375rem;
        box-sizing: border-box;
        height: 1.75rem;
        line-height: 1.75rem;
        background-color: #bcbcbc;
        color: #fff;
        font-size: 0.75rem;
        border-radius: 5px;
        text-align: center;
        &.success{
          background-color: #0173F7;
           &:active {
            opacity: 0.9;
          }
        }
      }
    }
  }
   .btn-wrap {
      padding: 0 1.25rem;
      margin-top: 2.5rem;
    }
</style>
